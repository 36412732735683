import CommonUtils from './commonUtils'
const CONFIG = {
  precisionSetting: {
    10: 8640, // 10s
    60: 1440, // 1min
    300: 288, // 5min
    600: 144, // 10min
    1800: 48, // 30min
    3600: 24, // 1h
  },
  wheelIndexMap: [3600, 1800, 600, 300, 60, 10],
  speed: 1, // 速度
}
/**
 * 时间轴组件类
 * @param {el} 元素选择器
 * @param {config} 配置参数
 */
class timeSlider extends CommonUtils {
  constructor(el, config = {}) {
    super();
    this.el = el;
    this.PRECISION = 3600 // 最小精度
    this.DAYSECONDS = 60 * 60 * 24 // 一天的秒数
    this.precisionSetting = CONFIG.precisionSetting // 精度对应参数
    this.wheelIndexMap = CONFIG.wheelIndexMap || [] // 精度对应参数

    this.curDaytimeChunkArray = config.curDaytimeChunkArray || [] // 当前时间轴数组

    this.curDayHeightArray = []

    this.presentSeconds = config.presentSeconds || 0 // 当前时间秒数
    this.pxY = 0 // 当前y轴位移
    this.listHeight = 100 // 时间块高度
    this.tipsSpan = null // 当前时间提示
    this.background = `rgb(164, 211, 255)`
    this.maxY = 0 //  最大位移

    this.callBack = config.callBack || function() {} // 回调函数
    
    // 初始化dom元素
    this.initDomInfo();
    this.initTimeChunk()

    // 初始化事件监听
    this.initRootDomEvent()
  }

  setPresentSeconds(presentSeconds) {

    if(this.isTouch) return

    this.presentSeconds = presentSeconds
    this.setTimeLinePosition()
  }

  // 初始化时间块 
  initTimeChunk() {

    this.containerDivWapper && this.containerDom.removeChild(this.containerDivWapper)
    
    this.containerDivWapper = document.createElement('div');
    this.containerDivWapper.classList.add('list-wapper');
    this.containerDom.appendChild(this.containerDivWapper);

    const { PRECISION, DAYSECONDS } = this

    for (let i = 0; i < DAYSECONDS / PRECISION + 1; i++) {
      const div = document.createElement('div')
      div.classList.add('list')
      div.style.height = `${ i < DAYSECONDS / PRECISION ? this.listHeight : 1 }px`
      const span = document.createElement('span')
      const line = document.createElement('div')
      line.classList.add('line')
      line.style.background = this.background
      span.innerText = this.secondsTranslateTimeUtils(i * PRECISION).slice(0, 5)

      div.appendChild(span)
      div.appendChild(line)
      this.containerDivWapper.appendChild(div)
    }


    this.curDayHeightArray = []

    for (let i = 0; i < this.curDaytimeChunkArray.length; i++) {
      const item = this.curDaytimeChunkArray[i].split('-')

      const div = document.createElement('div')
      div.classList.add('list-time')

      div.style.width = `100%`
      div.style.left = `0`
      div.style.position = `absolute`

      this.maxY = item[0] / this.PRECISION * this.listHeight

      div.style.top = `${this.maxY}px`
      div.style.height = `${(item[1] - item[0]) / this.PRECISION * this.listHeight + 1}px`
      div.style.background = this.background

      this.curDayHeightArray.push(item[1] / this.PRECISION * this.listHeight)

      this.containerDivWapper.appendChild(div)
    }

    this.initTipsChunk()
    this.setTimeLinePosition()
  }

  setTimeLinePosition() {

    this.pxY = -this.presentSeconds / this.PRECISION * this.listHeight

    this.containerDom.style.transform = `translateY(${this.pxY}px)`
    this.tipsSpan.innerText = `${this.secondsTranslateTimeUtils(this.presentSeconds)}`
  }
  

  initTipsChunk() {
    const div = document.createElement('div')
    div.classList.add('tips-time')
    const span = document.createElement('span')
    span.innerText = `00:00:00`
    div.appendChild(span)

    this.rootDom.appendChild(div)

    this.tipsSpan = span

    this.initButton()
  }

  initButton() {
    const div = document.createElement('div')
    div.style.position = 'absolute'
    div.style.right = '20px'
    div.style.top = this.listHeight

    const button1 = document.createElement('div')
    button1.innerText = "+"
    button1.style.width = '40px'
    button1.style.height = '40px'
    button1.style.border = `1px solid ${this.background}`
    button1.style.display = 'flex'
    button1.style.alignItems = 'center'
    button1.style.justifyContent = 'center'
    button1.style.marginBottom = '10px'
    button1.style.borderRadius = '6px'
    button1.style.fontSize = '28px'
    button1.style.color = "#3662EC"

    const button2 = document.createElement('div')
    button2.innerText = "-"
    button2.style.width = '40px'
    button2.style.height = '40px'
    button2.style.border = `1px solid ${this.background}`
    button2.style.display = 'flex'
    button2.style.alignItems = 'center'
    button2.style.justifyContent = 'center'
    button2.style.borderRadius = '6px'
    button2.style.fontSize = '28px'
    button2.style.color = "#3662EC"

    div.appendChild(button1)
    div.appendChild(button2)


    button1.addEventListener("click", () => {
      const index = this.wheelIndexMap.findIndex(item => item === this.PRECISION)
      if(index >= this.wheelIndexMap.length - 2) {
        return
      }

      this.PRECISION = this.wheelIndexMap[index + 1]

      this.initTimeChunk()
    })

    button2.addEventListener("click", () => {
      const index = this.wheelIndexMap.findIndex(item => item === this.PRECISION)
      if(index <= 0) {
        return
      }
      this.PRECISION = this.wheelIndexMap[index - 1]

      this.initTimeChunk()
    })

    this.rootDom.appendChild(div)
  }

  // 初始化dom信息
  initDomInfo() {
    this.rootDom = this.getDomInstanceUtils(this.el);
    // 创建容器元素
    let containerDiv = document.createElement('div');
    containerDiv.classList.add('times-slider-bg');
    this.rootDom.appendChild(containerDiv);
    // 时间轴元素
    this.containerDom = containerDiv
  }

  initRootDomEvent() {

    const that = this
    const sliderBg = this.containerDom
    const timesSlider = document.querySelector(this.el)
    const listWapperSlider = sliderBg.querySelector('.list-wapper')
    // console.log(listWapperSlider.clientHeight)
    const clientHeight = -listWapperSlider.clientHeight + 1
    this.clientHeight = clientHeight

    let startX, startY, endX, endY, deltaX, deltaY;
    timesSlider.addEventListener('touchstart', (e) => {

      this.isTouch = true

      // 获取触摸点的坐标
      startX = e.touches[0].clientX;
      startY = e.touches[0].clientY;
    }, false);

    timesSlider.addEventListener('touchmove', (e) => {
      // 阻止默认滚动行为（可选，视情况而定）
      e.preventDefault();
      // 获取触摸点的坐标
      endX = e.touches[0].clientX;
      endY = e.touches[0].clientY;

      // 计算滑动距离
      deltaX = endX - startX;
      deltaY = endY - startY;

      // 可以在这里添加逻辑来处理滑动事件，比如判断滑动方向等
      // 例如，如果deltaY大于某个阈值，可以认为是向上滑动
      if (Math.abs(deltaY)) {
        if (deltaY > 0) {
          console.log('向上滑动');
        } else {
          console.log('向下滑动');
        }

        that.pxY += deltaY

        if(that.pxY > -that.maxY) {
          that.pxY = -that.maxY
        }
        else 
        if(that.pxY <= -that.curDayHeightArray[0]) {
          that.pxY = -that.curDayHeightArray[0]
        }

        this.presentSeconds = -that.pxY / that.listHeight * that.PRECISION

        that.setTimeLinePosition()
      }

      // 重置起始位置为当前位置，以便计算下一次滑动的距离
      startX = endX;
      startY = endY;
    }, false);

    // 绑定touchend事件（可选，用于处理滑动结束后的逻辑）
    timesSlider.addEventListener('touchend', (e) => {
      this.callBack(this.presentSeconds)
        // ...
    }, false);
  }
}


export default timeSlider
