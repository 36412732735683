export const errorList={
  1001: '请求中止', 
  1002: '网络异常', 
  1003: '媒体资源解析错误', 
  1004: '不支持的媒体源', 
  1005: '媒体资源已加密', 
  1006: '媒体数据传输错误', 
  1007: '连接服务器失败', 
  1008: '不支持的解码类型', 
  1101: '商云服务器内部错误', 
  1102: 'token过期或不存在', 
  1104: '设备ID无效', 
  1105: '达到带宽限制', 
  1106: '请求预览地址参数错误', 
  1107: '连接relay服务器失败', 
  1108: '取流失败', 
  1109: '暂不支持此视频流协议版本', 
  1110: '已达到拉流请求上限', 
  1111: '已长时间观看', 
  1112: 'VIP窗口预览数达到上限', 
  1113: 'VIP预览客户端数量达到上限', 
  1114: '分享时段结束', 
  1115: '权限不足', 
  1120: '多媒体数据加密状态发生变化', 
  1121: '设备正在通话中', 
  1122: '解绑设备', 
  1201: '设备不支持语音对讲', 
  1202: '用户拒绝提供信息', 
  1203: '浏览器不支持硬件设备', 
  1204: '无法发现指定的硬件设备', 
  1205: '无法打开麦克风', 
  1206: '通话模式不支持', 
  1207: '音频设备正忙，无法发起通话', 
  1208: '浏览器获取麦克风权限被拒绝', 
  1301: '设备离线', 
  1302: '设备被移除', 
  1303: '设备密码被修改', 
  1401: '未知错误', 
  1402: '操作超时', 
  1403: '网络异常', 
  1404: '服务器内部错误', 
  1405: 'session过期或不存在', 
  1501: '回放配置失败', 
  2001: '分辨率过高导致视频卡顿', 
  2002: '网络不稳定导致视频卡顿', 
  2003: '解码性能不足'
}