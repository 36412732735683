<template>
  <div
    id="video-container"
    :class="{ 'full-screen': fullScreen }"
    @click="
      showTool = true;
      showToolChange();
    "
  >

    <div style="color:#ffffff;position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);" v-if="showTips">
      {{tips}}
    </div>
    <div class="mobile-player-tool" v-if="showTool">
      <van-icon
        @click="playOrPause"
        v-if="isStop"
        style="margin-right: auto"
        name="play"
      />
      <van-icon
        @click="playOrPause"
        v-else
        style="margin-right: auto"
        name="pause"
      />
      <span @click="handleScreenshot" class="btn">截图</span>
      <!-- <span v-if="record" @click="handleStopRecord" class="btn"
        >{{ recordTime }}s 停止</span
      >
      <span v-else @click="handleRecord" class="btn">录制</span> -->

      <span class="btn" @click="fullScreen = !fullScreen"
        >{{ fullScreen ? "取消" : "" }}全屏</span
      >
    </div>
  </div>

  <!-- <van-button type="primary">直播</van-button> -->
  <van-button style="margin-top:10px;margin-left:20px;" size="small" type="primary" color="#3662EC" @click="emit('emit')">查看录像回放</van-button>

  <!-- <van-date-picker
    v-model="currentDate"
    title="选择年月"
    :filter="filter"
    :columns-type="['year', 'month', 'day']"
  /> -->
</template>

<script setup>
import { onMounted, nextTick, ref, watch, defineEmits } from "vue";
import axios from "@/request";
import { showLoadingToast, closeToast, showToast } from "vant";
import { errorList } from "./config";


const emit = defineEmits(["emit"]);

const tips = ref("视频加载中，请稍后")


function getQueryParams(url) {
  // 如果未提供url，则默认使用当前页面的url
  if (!url) {
    url = window.location.href;
  }

  // 从url中提取查询字符串（?之后的部分）
  var queryString = url.split("?")[1];

  // 如果没有查询字符串，则返回空对象
  if (!queryString) {
    return {};
  }

  // 将查询字符串拆分为键值对数组
  var pairs = queryString.split("&");

  // 创建一个空对象来存储查询参数
  var params = {};

  // 遍历键值对数组，并将它们添加到params对象中
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    var key = decodeURIComponent(pair[0]);
    var value = decodeURIComponent(pair[1] || ""); // 如果没有值，则默认为空字符串

    // 如果键已经存在，并且值是一个数组，则将新值添加到数组中
    // 否则，将值设置为单个值或新数组
    if (params.hasOwnProperty(key)) {
      if (Array.isArray(params[key])) {
        params[key].push(value);
      } else {
        params[key] = [params[key], value];
      }
    } else {
      params[key] = value;
    }
  }

  // 返回查询参数对象
  return params;
}

const url = `http://localhost:8081/?deviceId=0c6ab2835ea2&code=TP10D58DF870E1AC91D3060&token=Bearer%20eyJhbGciOiJIUzUxMiJ9.eyJ0ZW5lbWVudElkIjowLCJtZW51Q2F0ZWdvcnkiOiIwIiwibG9naW5fdXNlcl9rZXkiOiI2MjhmNjJkNS1mYzYxLTQ1NGQtOTJjNC1kZTJjNjk5ZGFjNjkifQ.PtEfVdUw-2wy5XME-qngSYVc4pgdWX9A5XqqR4_iLWX0BX3RzU0dLkJpE-DewXCr9GeoBXmMDfykzDIVpcWl6A`
const urlParams = getQueryParams(process.env.NODE_ENV === "development" ? url : "")

sessionStorage.token = urlParams.token


const showCalendar = ref(!false);
const currentDate = ref(['2021', '01', '01']);


const height = ref("17.5625rem");

const showTool = ref(false);
const fullScreen = ref(false);
let showToolTimer = null;

function showToolChange() {
  showToolTimer && clearTimeout(showToolTimer);
  showToolTimer = setTimeout(() => {
    showTool.value = false;
  }, 4000);
}

// 播放器对象
let player = null;
const isStop = ref(false); // 是否正在播放
// 截图
function handleScreenshot() {
  player.screenshot();
}

// 录制
let record = ref(false);
let recordTime = ref(0);
let timer = null;
function handleRecord() {
  record.value = true;
  player.startRecording();

  timer = setInterval(() => {
    recordTime.value++;
  }, 1000);
}

function handleStopRecord() {
  player.stopRecording();
  record.value = false;
  timer ?? clearInterval(timer);
}
// 录制

// 播放暂停
function playOrPause() {
  if (!player) return;

  let isPlaying = player.isPlaying();
  if (!isPlaying) {
    if (player.isInit) {
      player.start();
    } else {
      player.play();
    }
    isStop.value = false;
  } else {
    isStop.value = true;
    player.pause();
  }
}

// 视频清晰度
let resolution = 0;
const showPicker = ref(false);

function resolutionChange(val) {
  showPicker.value = false;
  if (resolution === val) return;
  resolution = val;

  player?.destroy();
  getPlayerUrl();
}

// 初始化播放器
getPlayerUrl();

//获取视频
function getPlayerUrl() {
  showLoadingToast({
    message: "",
    forbidClick: true,
  });

  axios({
    url: `/manager/ipc/getStreamUrl`,
    method: "post",
    data: {
      deviceId: urlParams.deviceId,
      code: urlParams.code,
      streamType: "video",
      resolution: resolution,
    },
  }).then((res) => {
    if (res.code === 200) {
      nextTick(() => {
        initTumsPlayer(res.data);
      });
    }
  });
}

const showTips = ref(true); // 是否显示提示

function initTumsPlayer(res) {
  const TumsPlayer = window["tums-player"].default;
  // 预览初始化必填字段
  /* eslint-disable */
  player = new TumsPlayer("video-container", {
    type: "relay", // 协议类型relay
    url: res.streamUrl, // 取流地址
    appKey: res.ak, // 从商云平台获取的鉴权信息
    appSecret: res.sk, // 从商云平台获取的鉴权信息
  });

  player.on("playing", (evt) => {

    showTips.value = false;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { contentRect } = entry;
        height.value = contentRect.height + "px";
      }
    });

    resizeObserver.observe(evt.detail.player.canvas);
    closeToast();
  });

  player.on("error", (err) => {
    showTips.value = true
    const code = err.detail.code
    tips.value = errorList[code] || err.detail.message || "播放失败，请稍后重试"
    showToast(tips.value)
  })
}
</script>
<style lang="scss" scoped>
#video-container {
  width: 100%;
  height: v-bind("height");
  position: relative;
  min-height: 260px;
  z-index: 100;
  background: #404040;

  &.full-screen {
    height: 100vw;
    width: 100vh;
    transform-origin: 0 0;
    transform: rotate(90deg) translate(0, -100vw);
    // transform: rotate(90deg) translate(-100vw, 0);
    // transform: rotate(90deg) translate(-100%, -100%);
  }

  .mobile-player-tool {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 40px;
    z-index: 10;
    background: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    width: 100%;
    color: #ffffff;

    .btn {
      margin-left: 30px;
      position: relative;

      .popup-wapper {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 30px;
        background: rgba($color: #000000, $alpha: 0.5);
        line-height: 24px;
        padding: 5px 20px;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
