<template>

  <div class="video-back-container">
    
    <div
      id="video-back-container"
      :class="{ 'full-screen': fullScreen }"
      @click="
        showTool = true;
        showToolChange();
      "
    >

      <div style="color:#ffffff;position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);" v-if="showTips">
        {{tips}}
      </div>

      <div class="mobile-player-tool" v-if="showTool">
        <van-icon
          @click="playOrPause"
          v-if="isStop"
          style="margin-right: auto"
          name="play"
        />
        <van-icon
          @click="playOrPause"
          v-else
          style="margin-right: auto"
          name="pause"
        />

        <span class="btn" @click="showScale = true">{{scale}}X
          <div class="popup-wapper" v-if="showScale">
            <span @click="resolutionChange(0.5)">0.5X</span>
            <span @click="resolutionChange(1)">1X</span>
            <span @click="resolutionChange(2)">2X</span>
            <span @click="resolutionChange(4)">4X</span>
            <span @click="resolutionChange(8)">8X</span>
            <span @click="resolutionChange(16)">16X</span>
          </div>
        </span>

        <span @click="handleScreenshot" class="btn">截图</span>
        <!-- <span v-if="record" @click="handleStopRecord" class="btn"
          >{{ recordTime }}s 停止</span
        >
        <span v-else @click="handleRecord" class="btn">录制</span> -->

        <span class="btn" @click="fullScreen = !fullScreen"
          >{{ fullScreen ? "取消" : "" }}全屏</span
        >
      </div>
    </div>

    <!-- <van-button type="primary">直播</van-button> -->

    <div class="current-date-wapper">

      <div class="current-date" @click="showCalendar = true">录像时间：{{currentDateFormat}}</div>

      <van-button size="small" type="primary" color="#3662EC" @click="emit('emit')">查看直播</van-button>
    </div>
    <div style="width:100%;">
      <!-- <div id="timeSlider"></div> -->
      <div class="times-slider"></div>
    </div>
  </div>


  <van-popup v-model:show="showCalendar" position="bottom">
    <van-date-picker
      v-model="currentDate"
      title="选择年月"
      :filter="filter"
      :columns-type="['year', 'month', 'day']"
      @confirm="confirmCalendar"
      @cancel="showCalendar = false"
    />
  </van-popup>
</template>

<script setup>
import { onMounted, nextTick, ref, watch, computed, defineEmits } from "vue";
import axios from "@/request";
import { showLoadingToast, closeToast, showToast } from "vant";
import dayjs from "dayjs";
// import initTimeSlider from '@/assets/video-time-slider/src/index';
import { errorList } from "./config";
import initTimeSlider from '@/assets/time-slider/index';


const emit = defineEmits(["emit"]);

const tips = ref("视频录像加载中，请稍后")

/**
 * 从URL中获取查询参数
 *
 * @param url 待解析的URL，如果不提供则默认使用当前页面的URL
 * @returns 返回包含查询参数的对象，如果URL没有查询参数则返回空对象
 */
function getQueryParams(url) {
  // 如果未提供url，则默认使用当前页面的url
  if (!url) {
    url = window.location.href;
  }

  // 从url中提取查询字符串（?之后的部分）
  var queryString = url.split("?")[1];

  // 如果没有查询字符串，则返回空对象
  if (!queryString) {
    return {};
  }

  // 将查询字符串拆分为键值对数组
  var pairs = queryString.split("&");

  // 创建一个空对象来存储查询参数
  var params = {};

  // 遍历键值对数组，并将它们添加到params对象中
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    var key = decodeURIComponent(pair[0]);
    var value = decodeURIComponent(pair[1] || ""); // 如果没有值，则默认为空字符串

    // 如果键已经存在，并且值是一个数组，则将新值添加到数组中
    // 否则，将值设置为单个值或新数组
    if (params.hasOwnProperty(key)) {
      if (Array.isArray(params[key])) {
        params[key].push(value);
      } else {
        params[key] = [params[key], value];
      }
    } else {
      params[key] = value;
    }
  }

  // 返回查询参数对象
  return params;
}

const url = `http://localhost:8081/?deviceId=0c6ab2835ea2&code=TP10D58DF870E1AC91D3060&token=Bearer%20eyJhbGciOiJIUzUxMiJ9.eyJ0ZW5lbWVudElkIjowLCJtZW51Q2F0ZWdvcnkiOiIwIiwibG9naW5fdXNlcl9rZXkiOiI2MjhmNjJkNS1mYzYxLTQ1NGQtOTJjNC1kZTJjNjk5ZGFjNjkifQ.PtEfVdUw-2wy5XME-qngSYVc4pgdWX9A5XqqR4_iLWX0BX3RzU0dLkJpE-DewXCr9GeoBXmMDfykzDIVpcWl6A`
const urlParams = getQueryParams(process.env.NODE_ENV === "development" ? url : "")

sessionStorage.token = urlParams.token

const showCalendar = ref(false);

// 缓存数据
const selectTime = sessionStorage.getItem("currentDate") ? sessionStorage.getItem("currentDate") : dayjs().format('YYYY-MM-DD')
const currentDate = ref([dayjs(selectTime).format("YYYY"), dayjs(selectTime).format("MM"), dayjs(selectTime).format("DD")]);


const currentDateFormat = computed(() => {
  return dayjs(currentDate.value[0] + currentDate.value[1] + currentDate.value[2]).format("YYYY-MM-DD")
})

/**
 * 确认日历选择
 *
 * @param {object} options 选项对象
 * @param {Array} options.selectedValues 被选中的日期值数组
 */
function confirmCalendar({selectedValues}) {
  currentDate.value = selectedValues
  showCalendar.value = false

  videosTimeList = []
  
  sessionStorage.setItem('currentDate', currentDateFormat.value)

  window.location.reload();
}


/**
 * 获取列表记录日期
 *
 * @returns 无返回值，但会执行异步操作并更新 filterListDate 变量
 */
let filterListDate = []
function getListRecordDates() {

  return new Promise((resolve, reject) => {

    if(sessionStorage.getItem('currentDate')) {

      filterListDate = sessionStorage.getItem('filterListDate') ? JSON.parse(sessionStorage.getItem('filterListDate')) : []

      resolve(dayjs(currentDateFormat.value).format('MMDD'))
      return
    }

    showLoadingToast()

    axios({
      url: `/manager/ipc/listRecordDates`,
      method: "post",
      data: {
        code: urlParams.code,
        year: currentDateFormat.value.slice(0, 4),
      },
    }).then((res) => {
      if (res.code === 200) {
        sessionStorage.setItem('filterListDate', JSON.stringify(res.data.dates))

        filterListDate = res.data.dates
        closeToast()
      }

      currentDate.value[1] = filterListDate[filterListDate.length - 1].slice(0, 2)
      currentDate.value[2] = filterListDate[filterListDate.length - 1].slice(2, 4)

      resolve(filterListDate[filterListDate.length - 1])
    }, () => {
      showToast("请求失败")
      reject()
    })
  })
}
/**
 * 过滤函数，根据给定的类型和选项进行过滤
 *
 * @param type 过滤类型，可选值为 'year'、'month'、'day'
 * @param options 需要过滤的选项数组
 * @returns 返回过滤后的选项数组
 */
const filter = (type, options) => {

  const year = currentDate.value[0]
  const month = currentDate.value[1]
  const monthArr = filterListDate.map(item => item.slice(0, 2))

  if(type === 'year') {
    return options.filter((option) => option.value === year); 
  }

  if (type === 'month') {
    return options.filter((option) => monthArr.includes(option.value));
  }
  if(type === 'day') {
    return options.filter((option) => filterListDate.includes(month + option.value));
  }
}

const height = ref("14.625rem");

const showTool = ref(false);
const fullScreen = ref(false);
let showToolTimer = null;

function showToolChange() {
  showToolTimer && clearTimeout(showToolTimer);
  showToolTimer = setTimeout(() => {
    showTool.value = false;
    showScale.value = false;
  }, 4000);
}

// 播放器对象
let player = null;
const isStop = ref(false); // 是否正在播放
// 截图
function handleScreenshot() {
  player.screenshot();
}

// 录制
let record = ref(false);
let recordTime = ref(0);
let timer = null;
function handleRecord() {
  record.value = true;
  player.startRecording();

  timer = setInterval(() => {
    recordTime.value++;
  }, 1000);
}

function handleStopRecord() {
  player.stopRecording();
  record.value = false;
  timer ?? clearInterval(timer);
}
// 录制

// 播放暂停
function playOrPause() {
  if (!player) return;

  let isPlaying = player.isPlaying();
  if (!isPlaying) {
    if (player.isInit) {
      player.start();
    } else {
      player.play();
    }
    isStop.value = false;
  } else {
    isStop.value = true;
    player.pause();
  }
}

// 视频清晰度
let resolution = 0;
//获取视频
function getPlayerUrl(startTime, userId) {
  showLoadingToast({
    message: "",
    forbidClick: true,
  });

  axios({
    url: `/manager/ipc/getStreamUrl`,
    method: "post",
    data: {
      deviceId: urlParams.deviceId,
      code: urlParams.code,
      streamType: "sdvod",
      resolution: resolution,
    },
  }).then((res) => {
    if (res.code === 200) {
      nextTick(() => {
        initTumsPlayer(res.data, startTime, userId);
      });
    }
  });
}

const showTips = ref(true); // 是否显示提示


/**
 * 初始化TumsPlayer播放器
 *
 * @param res 初始化参数对象
 * @param res.streamUrl 取流地址
 * @param res.ak 从商云平台获取的鉴权信息（appKey）
 * @param res.sk 从商云平台获取的鉴权信息（appSecret）
 * @returns 无返回值
 */
function initTumsPlayer(res, startTime, userId) {
  const TumsPlayer = window["tums-player"].default;
  // 预览初始化必填字段
  /* eslint-disable */
  player = new TumsPlayer("video-back-container", {
    type: "relay", // 协议类型relay
    url: res.streamUrl, // 取流地址
    streamType: 'sdvod',
    scale: scale.value, // 视频进度比例
    startTime: startTime,
    userId: userId,
    eventType: [1],
    // qrCode: "0D58DF870E1AC91D3",
    appKey: res.ak, // 从商云平台获取的鉴权信息
    appSecret: res.sk, // 从商云平台获取的鉴权信息
  });

  player.on("playing", (evt) => {

    showTips.value = false;

    if(timeSlider) {
      setTimeout(() => {
        timeSlider.isTouch = false
      }, 520);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { contentRect } = entry;
        height.value = contentRect.height + "px";
      }
    });

    resizeObserver.observe(evt.detail.player.canvas);
    closeToast();

  });

  player.on("error", (err) => {
    showTips.value = true;
    const code = err.detail.code
    tips.value = errorList[code] || err.detail.message || "播放失败，请稍后重试"
    showToast(tips.value)
  })
}

// 获取视频回放时间列表
let videosTimeList = [];
let userId
function getListVideo(searchDay, startIndex, endIndex, callBack) {
  axios({
    url: `/manager/ipc/listVideo`,
    method: "post",
    data: {
      code: urlParams.code, //设备id或mac，设备mac地址，格式如00-FF-00-17-00-18，字母大写并用"-"分隔，设备id：扫码获得
      searchDay: searchDay, //日期，yyyyMMDD的格式
      startIdx: startIndex, //开始索引
      endIdx: endIndex //结束索引，endIdx和startIdx差值不能超过100
    },
  }).then(res => {
    if (res.code === 200) {
      let videos = res.data.videos.filter(item => item.videoType.includes(1)).map(item => {
        
        const start = dayjs.unix(item.startTime).format('HHmmss').valueOf()
        const end = dayjs.unix(item.endTime).format('HHmmss').valueOf()

        const curDaytimeChunkArray1 = start.slice(0, 2) * 60 * 60 + start.slice(2, 4) * 60 + Number(start.slice(4,6))
        const curDaytimeChunkArray2 = end.slice(0, 2) * 60 * 60 + end.slice(2, 4) * 60 + Number(end.slice(4,6) - 1) // 拉到左后会报错

        return {
          ...item,
          curDaytimeChunkArray1: curDaytimeChunkArray1,
          curDaytimeChunkArray2: curDaytimeChunkArray2,
        }
      });
      videosTimeList = [...videosTimeList, ...videos];

      const nums = endIndex + 1 - startIndex

      if(res.data.videos.length < nums){
        userId = res.data.userId
        callBack && callBack(userId)
      }else {
        getListVideo(searchDay, startIndex + nums, endIndex + nums, callBack)
      }
    }
  })
}

let timerSlider = null
function syncTimeSliderPresentSeconds() {
  timerSlider && clearInterval(timerSlider)

  timerSlider = setInterval(() => {
    if(player) {
      const time = dayjs(player.getPlaybackTime())
      let presentSeconds = Number(time.format("HH")) * 60 * 60 + Number(time.format("mm")) * 60 + Number(time.format("ss"))
      timeSlider.setPresentSeconds(presentSeconds)
    }
  }, 500);
}


function initFunc(searchDay) {

  showLoadingToast()

  getListVideo(searchDay, 0, 99, (userId) => {
    const startTime = dayjs(currentDateFormat.value).valueOf()

    getPlayerUrl(startTime, userId);

    setTimeout(() => {
      closeToast()
      initTimeSliderFunc()

      syncTimeSliderPresentSeconds()
      
    }, 1000);
  })
  // 初始化播放器
}

getListRecordDates().then((searchDay) => {
  initFunc(currentDate.value[0] + searchDay)
  // 初始化播放器
})

let timeSlider = null
const scale = ref(1)
function initTimeSliderFunc() {

  const curDaytimeChunkArray1 = videosTimeList[0].curDaytimeChunkArray1
  const curDaytimeChunkArray2 = videosTimeList[videosTimeList.length - 1].curDaytimeChunkArray2 // 拉到左后会报错

  timeSlider = initTimeSlider('.times-slider',{
    curDaytimeChunkArray: [`${curDaytimeChunkArray1}-${curDaytimeChunkArray2}`],
    callBack: (val) => {
      
      const startTime = videosTimeList[0].startTime * 1000 + (val - curDaytimeChunkArray1) * 1000
      const endTime = videosTimeList[videosTimeList.length - 1].endTime * 1000

      console.log(startTime, endTime)

      timeSlider.isTouch = true

      player.setPlaybackConfig({
        startTime: startTime, // 回放开始时间设置
        endTime: endTime, // 回放结束时间设置
        // scale: scale.value, // 回放倍率
        eventType: [1] // 回放事件类型
      });
      
    }
  })
}


const showScale = ref(false)

function resolutionChange(val) {

  scale.value = val;
  
  setTimeout(() => {
    showScale.value = false;
  }, 100);

  // const startTime = player.getPlaybackTime()
  // const endTime = videosTimeList[videosTimeList.length - 1].endTime * 1000;

  player.setPlaybackConfig({
    // startTime: startTime, // 回放开始时间设置
    // endTime: endTime, // 回放结束时间设置
    scale: scale.value, // 回放倍率
    eventType: [1] // 回放事件类型
  });
}


</script>

<style lang="scss" scoped>

// .video-back-container{
//   height: 100vh;
// }

#video-back-container {
  width: 100%;
  height: v-bind("height");
  min-height: 230px;
  position: relative;
  z-index: 100;
  background: #404040;


  &.full-screen {
    height: 100vw;
    width: 100vh;
    transform-origin: 0 0;
    transform: rotate(90deg) translate(0, -100vw);
    // transform: rotate(90deg) translate(-100vw, 0);
    // transform: rotate(90deg) translate(-100%, -100%);
  }

  .mobile-player-tool {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 40px;
    z-index: 10000;
    background: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    width: 100%;
    color: #ffffff;
    transform: rotate(0) translate(0, 0);

    .btn {
      margin-left: 30px;
      position: relative;

      .popup-wapper {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 30px;
        background: rgba($color: #000000, $alpha: 0.5);
        line-height: 30px;
        padding: 10px 20px;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.times-slider{

}

.current-date-wapper{
  display: flex;
  align-items: center;
  padding: 10px 20px;
  .current-date{
    margin-right: auto;
  }
}

// .current-date{
//   position: fixed;
//   bottom: 0;
//   height: 68px;
//   margin-bottom: 20px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 100vw;
// }

</style>
