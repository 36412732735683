<template>
  <div class="tp-link">
    <VideoPlayer v-if="showPlayer" @emit="emit(false)"></VideoPlayer>
    <VideoBackPlayer v-else @emit="emit(true)"></VideoBackPlayer>

    <!-- <Test></Test> -->
  </div>
</template>

<script>
import VideoPlayer from "./VideoPlayer";
import VideoBackPlayer from "./VideoBackPlayer";
import Test from "./Test";

export default {
  name: "App",
  components: {
    VideoPlayer,
    VideoBackPlayer,
    Test,
  },
  data() {
    return {
      showPlayer: sessionStorage.showPlayer === "false" ? false : true,
    };
  },
  created() {
    console.log(sessionStorage.showPlayer);
  },
  methods: {
    emit(showPlayer) {
      sessionStorage.showPlayer = showPlayer;
      this.showPlayer = showPlayer;
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
html {
  font-size: calc(100vw / 1.875);
}

.tp-link {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-right: env(safe-area-inset-right);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-left: env(safe-area-inset-left);
}
</style>