import axios from "axios"


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 30000
})

service.interceptors.request.use(config => {
  // 是否需要设置 token
  config.headers['Authorization'] = 'Bearer ' + sessionStorage.token // 让每个请求携带自定义token 请根据实际情况自行修改

  return config
}, error => {
    Promise.reject(error)
})

service.interceptors.response.use(res => {
  return res.data
})

export default service