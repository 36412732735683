import { createApp } from 'vue'
import App from './App.vue'
import Vant from 'vant';
// import VConsole from 'vconsole'

import 'vant/lib/index.css';
import "@/assets/styles/reset.scss"

const app = createApp(App)
app.use(Vant);

app.mount('#app')

// process.env.NODE_ENV === "development" && new VConsole()

