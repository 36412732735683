<template>
  <!-- <div id="timeSlider"></div> -->

  <div class="times-slider">
    <!-- <div class="times-slider-bg">
      <div class="list-wapper">
        <div class="list" v-for="item in 10" :key="item">
          <span>10:{{item}}0</span>
          <div class="line"></div>
        </div>
      </div>
    </div>

    <div class="tips-time">
      <span>0{{ Y }}:00</span>
    </div> -->
  </div>
</template>
<script>

import initTimeSlider from '@/assets/time-slider/index';

export default {
  mounted () {
    const slider = initTimeSlider(".times-slider", {
      curDaytimeChunkArray: [`0-43200`]
    })

    // let presentSeconds = 0;
    // setInterval(() => {
    //   presentSeconds++
    //   slider.setPresentSeconds(presentSeconds)
    // }, 1000);
  }
}
</script>
